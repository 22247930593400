<template>
<div class="main">
  <div class="component-content">
    <Loading v-if="loading"/>

    <TopMenu class="mb-12p">

      <template v-slot:page-name>盘点</template>

      <template v-slot:menu-block1>
        <button class="btn btn-blue" :disabled="allowAttack" @click="save()">
          <span class="mr-2">保存</span>
          <img class="icon-16 d-inline-block" src="/static/images/icon/save.svg">
        </button>
      </template>

    </TopMenu>

    <div class="content bg-25 h-100">
  
      <div class="flex-list hr w-100">
        <div style="width:20%;">名称</div>
        <div style="width:20%;">规格</div>
        <div style="width:20%;">型号</div>
        <div style="width:20%;">系统库存数量</div>
        <div style="width:20%;">实际库存数量</div>
      </div>

      <div class="flex-list hr w-100 btn-default" v-for="(data) in datas.product" :key="data.id">
        <div style="width:20%;">{{ data.name }}</div>
        <div style="width:20%;">{{ data.specification }}</div>
        <div style="width:20%;">{{ data.model }}</div>
        <div style="width:20%;">{{ data.quantity }}</div>
        <div style="width:20%;">
          <input type="number" class="form-control p-0 bg-none" placeholder="0" autoComplete="off" v-model="data.real_quantity">
        </div>
      </div>

    </div>

    <div class="pagination">
      <v-pagination
        v-model="currentPage"
        :pages="totalPage"
        active-color="#004bff"
        @update:modelValue="getDatas($event, submenu.search.value)"
      />
    </div>
  </div>
</div>
</template>

<script>
import TopMenu from '@/components/TopMenu.vue'
//import SubMenu from '@/components/SubMenu.vue'

export default {
  name: 'StockTaking',

  data() {
    return {
      loading: true,
      datas: [],
      active: null,
      check: [],
      checkAll: false,
      currentPage: 1,
      totalPage: 1,
      allowAttack: false,
      submenu: {
        search: {
          value: '',
        }
      }
    }
  },

  mounted() {
    console.log(this.$options.name+' 挂载')

    this.getDatas()
  },

  beforeUnmount() {
    console.log(this.$options.name+'销毁完成')
  },

  components: {
    TopMenu,
    //SubMenu
  },

  methods: {
    getDatas(page, search) {
      let _this = this

      return new Promise((resolve, reject) => {
        let p = page ? page : 1
        _this.$axios.get('/api/product', {
          headers: {
            token: JSON.parse(sessionStorage.Authorization).token
          },
          params: {
            page: p,
            take: 20,
            search: search
          }
        })
        .then(res => {
          resolve(res);

          if (res.status == 200) {
            if (res.data) {

              res.data.product.forEach(function(item) {
                item.real_quantity = null
              })

              console.log(res.data)
              this.datas = res.data
              this.currentPage = res.data.current_page
              this.totalPage = res.data.total_page
            }
            this.loading = false
          }
          //console.log(res)
        })
        .catch(error => {
          reject(error)
          console.log('error:', error)
        })
      });
    },

    save() {
      let _this = this
      this.allowAttack = true
      return new Promise((resolve, reject) => {
        try {

          let datas = []
          _this.datas.product.forEach(function(item) {
            if (item.real_quantity) {
              datas.push({
                id: item.id,
                real_quantity: item.real_quantity
              })
            }
          })

          if (datas.length == 0) {
            throw '请填写实际库存数量'
          } else {
            datas = JSON.stringify(datas)
          }

          _this.$axios.post('/api/inbound/stock-taking',
            {
              datas: datas
            },
            {
              headers: {
                token: JSON.parse(sessionStorage.Authorization).token
              }
            }
          )
          .then(res => {
            _this.allowAttack = false

            if (res.status == 200) {
              resolve(res);
              console.log(res.data)
              if (res.data.success) {
                _this.datas = res.data.data
                _this.currentPage = res.data.data.current_page
                _this.totalPage = res.data.data.total_page
                _this.$toast.success(res.data.message)
              } else {
                _this.$toast.error(res.data.message)
              }
              
            }
          })
          .catch(error => {
            reject(error)
            _this.allowAttack = false
            console.error('error:', error)
          })
         
        } catch(error) {
          console.error(error)
          _this.allowAttack = false
          this.$toast.error(error)
        }
      })
    },

    //子组件方法
    _search(e) {
      this.submenu.search.value = e.target.value;
      this.getDatas(null, e.target.value)
    },

  },
  
}
</script>

<style scoped>
.active {
  background-color: #004bff !important;
}

.flex-list {
  padding: 8px 16px;
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-6 {
  position: relative;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-6:first-child {
  padding-left: 0;
}
.col-6:last-child {
  padding-right: 0;
}

</style>
